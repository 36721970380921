.hamburger-menu-container {
  /* min-height: 100vh; */
  /* height: max-content; */
  /* background-color: var(--primary-background-dark); */
  display: flex;
  flex-direction: column;
  color: aliceblue;
  border-right: 1px solid var(--stroke-sub-400, #99a0ae);
  background: #090a0d;
  color: var(--text-disabled-300, #cacfd8);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.084px;
  position: sticky;
  width: 140px;
}
.hamburger-menu-icon {
  padding: 8px 0 0 0;
  cursor: pointer;
  font-size: 20px;
  transform: scale(2);
  /* padding: var(--deafult-padding); */
  color: var(--theme-color);
}
.hamburger-menu-header-container {
  display: flex;
  padding: 8px 12px;
  padding-top: 32px;
  color: var(--text-white-0, #fff);
  font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
  /* Title/H6 Title */
  font-family: Inter !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 155.556% */
  gap: 10px;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}
.hamburger-menu-header {
  padding: 8px 0;
  cursor: pointer;
  color: var(--text-white-0, #fff);

  /* Title/H6 Title */
  font-family: Inter !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 155.556% */
}
.expanded {
  width: 240px !important;
  animation-name: MenuContractAnimation;
  animation-duration: 1s;
  transform: 0.5s ease-in-out;
}
.contracted {
  margin-top: auto;
  width: 140px;
  animation-name: MenuExpandAnimation;
  animation-duration: 1s;
}
@media screen and (max-width: 1070px) {
  .expanded {
    width: 140px !important;
  }
}
/* @media screen and (max-width:991px){
  .expanded{
    width: 140px !important;
  }
 } */

.menu-container-body {
  /* padding: var(--deafult-padding); */
  padding-top: var(--default-padding);
  width: 140px;
  height: 100%;
  /* overflow-y: auto; */
}

.menu-container-body ul {
  /* padding: var(--deafult-padding); */
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.menu-container-body ul li {
  cursor: pointer;
  /* width: 74px; */
  margin: 0 auto;
  flex-direction: column;
  display: flex;
  border-radius: 0.475rem;
  align-items: center;
  padding: 1rem 1rem;
}
.menu-container-body ul li svg {
  width: 26px;
  height: 26px;
}

/* .menu-container-body ul li:hover {
  transition: 0.2s ease-in-out;
  background: #282861;
} */

.menu-container-footer ul li:hover {
  transition: 0.2s ease-in-out;
  background: #282861;
}

footer {
  bottom: 0;
  /* position: fixed; */
  /* top: 650px; */
  top: 90%;
}
footer > ul {
  width: 100%;
  margin-bottom: 10px;
}
.logo {
  color: #1b84ff;
  font-family: Inter, Helvetica, "sans-serif";
  font-size: 24px;
}

@keyframes MenuExpandAnimation {
  from {
    width: 100px !important;
  }
  to {
    width: 300px !important;
  }
}
@keyframes MenuContractAnimation {
  from {
    width: 300px !important;
  }
  to {
    width: 100px !important;
  }
}

/* @media screen and (max-width: 880px) {
  .contracted {
    width: 50px !important;
  }
  .expanded {
    width: 80px !important;
  }

  .hamburger-menu-header-container {
   
    margin: 0px;
    font-size: 10px !important;

    width: 92%;
  }
  .hamburger-menu-icon {
    font-size: 15px !important;
  }
  .menu-container-body ul li {
    padding: 10px !important;
    font-size: 10px !important;
  }
  .expanded-menu {
    width: 97% !important;
  }
} */

.expanded-menu {
  display: flex;
  justify-content: space-between;
}
.contracted-menu {
  display: flex;
  justify-content: space-between;
}
.menu-title {
  color: #4b5675;
  font-size: 13px !important;
  font-weight: 600;
  font-family: Inter, Helvetica, "sans-serif";
}
.menu-container-body ul li:hover .menu-title {
  color: #f5f5f5;
}
.menu-container-body ul li:hover svg path {
  fill: #1b84ff;
}
