.rules-main-container {
  padding: 20px !important;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow-x: scroll !important;
}
@media screen and (max-width: 991px) {
  .rules-main-container {
    padding: 5px !important;
  }
}

.arrow-button {
  display: inline-block;
  width: 40px;
  height: 40px;
  position: relative;
  cursor: pointer;
}

.arrow-button::before,
.arrow-button::after {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  border-bottom: 5px solid black;
  border-left: 5px solid black;
  position: absolute;
  left: 50%;
  transform: translateX(-50%) rotate(-45deg);
}
.arrow-icon {
  /* cursor: pointer; */
}

.arrow-button.enabled {
  background-color: #b6d3fe;
  color: #0d6efd;
  cursor: pointer;
}
.arrow-button.disabled {
  pointer-events: none;
  background-color: rgb(169, 169, 169);
  color: grey;
}
.arrow-button::after {
  top: 10px; /* Shift second chevron down */
}

.arrow-button::before {
  top: 0; /* First chevron */
}

.rules-setting-container {
  /* padding: var(--deafult-padding) 0; */
  /* margin-top: 20px; */
  /* height: 300px; */
  /* background-color: aqua; */
  /* width: 100%; */
  background: #fff;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.rules-setting-body {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: var(--radius-12, 12px) var(--radius-12, 12px) 0px 0px;
  /* margin-bottom: -10px; */
  margin-top: -15px;
}
.rules-add-body {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: "space-between";
  align-items: flex-start;
  /* padding: 05px 24px; */
  font-size: 20px;
  font-weight: 700;
  width: 100%;
}

.rule-fields {
  display: "flex";
  flex-direction: "row";
  align-items: "center";
  gap: "20px";
}

.rules-header-container {
  align-content: flex-start;
  /* align-self: stretch; */
  flex-wrap: wrap !important;
  display: flex;
  gap: 16px;
}
.header-title-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-right: 20px;
  align-items: baseline;
}
@media (max-width: 991px) {
  .header-title-container {
    max-width: 100%;
  }
}
/* @media screen and (max-width: 991px) {
  .rules-setting-container {
    width: 90% !important;
  }
} */
.header-action {
  cursor: pointer;
  justify-content: space-between;
  border-radius: var(--radius-10, 10px);
  background-color: #512dd6;
  align-self: start;
  display: flex;
  gap: 4px;
  font-size: 14px;
  color: var(--static-static-white, #fff);
  font-weight: 500;
  letter-spacing: -0.08px;
  line-height: 143%;
  padding: 10px;
}

.header-action-img {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 20px;
}
.header-action-title {
  font-family: Inter, sans-serif;
}
.rule-name {
  color: var(--Gray-900, #101828);
  /* Label/Large */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 24px; /* 133.333% */
  letter-spacing: -0.27px;
  padding-left: 10px;
}
.rules-settings-buttons-container {
  display: flex;
  padding: 16px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border-top: 1px dashed #dbdfe9;
  gap: 8px;
}
.rules-settings-buttons-container > button,
.processor-settings-buttons > button {
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  text-transform: capitalize !important;
  color: black !important;
  border-radius: var(--radius-8, 8px);
  border: 1px solid #9c9cad;
  background: var(--bg-white-0, #fff);
  box-shadow: 0px 1px 2px 0px rgba(10, 13, 20, 0.03);
}
.processor-value {
  color: var(--text-sub-600, #525866);

  /* Paragraph/X Small */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}
.processor-name {
  color: var(--text-strong-950, #0e121b);
  /* Label/Small */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.084px;
}
.rule-container {
  display: flex;
  align-items: start;
  justify-self: center;
  flex-direction: column;
  color: var(--text-strong-950, #0e121b);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.084px;
  padding: 18px;
  border-radius: var(--radius-12, 12px);
  border: 1px solid #99a0ae;
  background: #fff;
  margin-top: 10px;
  margin-bottom: 45px;
  width: 97%;
  flex-wrap: wrap;
}
/* @media screen and (max-width: 1320px) {
  .rule-container {
    width: 90% !important;
    overflow-x: scroll !important;
  }
  .rules-header-container {
    width: 90% !important;
    overflow-x: scroll !important;
  }
} */

.select-connection-button {
  /* margin-left: -70px !important; */
  /* margin-top: -20px !important; */
  margin-bottom: 10px !important;
  color: black !important;
  text-transform: capitalize !important;
  font-size: 18px;
}

.processor-button {
  text-transform: capitalize !important;
  color: black !important;
  margin-left: 45px !important;
  min-width: 230px !important;
}

.select-new-processor-text {
  color: black !important;
  margin-left: 15px !important;
  margin-top: -10px !important;
  padding: 11px 10px !important;
  border: 1px solid var(--stroke-soft-200, #cacfd8) !important;
  border-radius: 4px;
  width: fit-content !important;
}
.selected-processor-text {
  border-radius: var(--radius-10, 10px);
  border: 1px solid var(--stroke-soft-200, #cacfd8) !important;
  background: var(--bg-white-0, #fff);
  color: var(--text-sub-600, #525866) !important;
  text-transform: capitalize !important;
  font-weight: 500 !important;
  padding: 10px 13px !important;
  margin-left: 55px !important;
  border-radius: 4px !important;
}
.rules-condition-sub-container {
  border-radius: var(--radius-12, 12px);
  border: 1px solid #99a0ae;
  background: #fff;
  margin-top: 10px;
}
.button-container {
  position: relative;
  height: 300px;
  width: 100px;
}

.button {
  position: absolute;
  width: 80px;
  height: 40px;
  background-color: lightcoral;
  color: white;
  border: none;
  cursor: pointer;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  transition: top 0.3s ease;
}

.add-processor-error-text {
  color: rgb(221, 33, 33);
  letter-spacing: -0.08px;
  font-size: 12px;
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
  display: flex;
  align-items: stretch;
  margin-top: 5px;
}
.hide-error-text {
  display: none;
}

.condition-container {
  margin-top: 10px;
  margin-left: 20px;
  width: 800px !important;
  /* background: rgb(154, 144, 154) !important; */
}
.conditions-main-container {
  padding: 15px;
}

.rule-empty-message {
  padding-top: 10px;
}
/* @media screen and (max-width: 991px) {
  .conditions-main-container {
    padding: 0px !important;
    width: 100%;
  }
  .rules-add-body {
    width: 90% !important;
    overflow-x: scroll !important;
  }
  .rules-condition-sub-container {
    overflow-x: scroll !important;
  }
  .rules-setting-container {
    padding: 5px !important;
    flex-wrap: wrap;
    overflow-x: scroll !important;
  }

  .rule-container {
    padding: 5px !important;
    overflow-x: scroll !important;
  }
  .nested-element {
    font-size: 10px !important;
  }

  .rules-settings-buttons-container > button {
    padding: 5px 8px !important;
    font-size: 10px !important;
  }
  .new-rule-text,
  .rules-status,
  .rules-condition {
    font-size: 10px !important;
  }
  .rules-setting-body {
    flex-wrap: wrap;
    overflow-x: scroll !important;
    width: 100% !important;
  }
  .processor-button {
    font-size: 12px !important;
    padding: 5px !important;
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .selected-processor-text {
    font-size: 12px !important;
    padding: 5px !important;
    margin-left: 40px !important;
  }
  .rule-name {
    font-size: 12px !important;
    padding-left: 10px !important;
  }
  .select-connection-button {
    padding: 5px !important;
  }
  .new-rule-input-field .MuiOutlinedInput-root {
    height: 20px !important;
    padding: 20px 0px 15px 0px !important;
    font-size: 12px !important;
  }
  .select-new-processor-text {
    font-size: 12px !important;
    padding: 5px !important;
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .rules-name-container {
    width: 50px !important;
  }
} */

@media screen and (max-width: 576px) {
  .rules-add-body {
    width: 98% !important;
  }
}
/* @media screen and (max-width: 991px) {
  .rules-add-body {
    width: 90% !important;
  }
} */
@media screen and (max-width: 1070px) {
  .rules-add-body {
    width: 90% !important;
  }
  /* .rules-condition-sub-container {
    width: 100%;
    overflow-x: scroll !important;
  } */
}

/* @media screen and (max-width: 1450px) {
  .nested-rules-container {
    overflow-x: scroll;
    padding: 0px 10px;
  }
} */

.nested-rules-container {
  overflow-x: scroll;
  padding: 0px 10px;
}
.d-none {
  display: none !important;
}

.disable {
  background-color: var(--Bg-Danger);
  color: var(--Text-Danger) !important;
}

.enable {
  background-color: var(--Bg-success);
  color: var(--Text-success) !important;
}
.status {
  margin-left: 15px;
  display: inline-block;
  padding: 0.325rem 0.5rem;
  font-size: 12px;
  font-weight: 600;
  color: #071437;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 6px;
}
.buttons-hide {
  display: none;
}
.buttons-show {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.text-transform {
  text-transform: capitalize !important;
}
