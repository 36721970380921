/* General Styles */

.config-page-text {
  color: var(--Gray-900, #101828);
  font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
  font-family: Inter;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  letter-spacing: -0.27px !important;
  display: flex;
  align-self: stretch;
}

.main-container .section {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-right: 30%; */
  min-width: 300px;
}

.hr-line-label-container {
  padding: 1.5px 0px;
  gap: 8px;
  margin: 20px 0px;
}

.payments-border-container {
  /* border: 1px solid var(--stroke-soft-200, #cacfd8); */
  border-radius: 8px;
  margin: 20px 0px;
  min-width: 235px !important;
  padding: 12px;
}

.checkout-text {
  color: var(--text-strong-950, #0e121b) !important;
  font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
  font-family: Inter;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 20px;
  letter-spacing: -0.084px;
}

.hr-line {
  background: var(--stroke-soft-200, #cacfd8);
  height: 1px;
  flex: 1 0 0;
}

.config-page label {
  font-size: 16px;
  font-weight: bold;
}

.config-page select,
.config-page input {
  padding: 8px;
  font-size: 16px;
  margin-left: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100px;
}

/* Color Picker Styling */
.color-picker-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.color-picker-swatch {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px;
  border: 2px solid #ccc;
  /* Add border around the color swatch */
  transition: border-color 0.3s ease;
}

.color-picker-swatch:hover {
  border-color: #999;
  /* Highlight border on hover */
}

.color-picker-popup {
  position: absolute;
  z-index: 2;
  margin-top: 5px;
}

.input-50 {
  width: 50%;
}

.color-picker-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
  margin-left: 20px;
}

/* Preview Box Styling */
.preview-box {
  margin-top: 30px;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #ccc;
}

.preview-box h2 {
  font-size: 20px;
  margin-bottom: 15px;
}

.preview-box .content {
  padding: 20px;
  background-color: white;
  color: black;
  font-weight: normal;
  font-size: 16px;
  border-radius: 8px;
  text-align: center;
}

.preview-box .content p {
  margin: 0;
}

/* @media screen and (max-width: 880px) {
  .config-page-text {
    font-size: 12px !important;
  }
} */

.base-MenuButton-root:hover {
  background-color: inherit !important;
}

.font-weight-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  width: 100px;
  padding: 0px 10px;
  color: #252f4a;
  font-weight: bold;
  font-size: 0.8125rem;
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

.font-weight-dropdown-container {
  position: absolute;
  z-index: 1000;
  /* background: #ffffff;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 130px;
  padding: 5px 10px;
  margin-top: 5px; */
  background: #ffffff !important;
  padding: 10px !important;
  border-radius: 2px !important;
  box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15) !important;
  border: none !important;
  margin-left: -80px;
  margin-top: 10px;
}

.each-font-weight-list {
  /* display: flex;
  align-items: center !important;
  padding: 5px;
  margin-bottom: 5px !important; */
  cursor: pointer;
  display: flex;
  align-items: center;
  flex: 0 0 100%;
  padding: 0.65rem 1rem;
  /* transition: none; */
  outline: 0 !important;
}
.each-font-weight-list:hover {
  /* background-color: #e9f3ff; */
  /* border: 3px solid #1b84ff;
  border-radius: 8px;
  padding: 2px; */
  /* transition: color 0.2s ease; */
  border: 3px solid #1b84ff;
  color: #1b84ff;
  border-radius: 4px;
}

.font-weight-dot-circle {
  font-size: 18px !important;
  color: #1b84ff !important;
  margin-right: 10px !important;
}
.selected-background {
  /* display: flex;
  align-items: center;
  background: #e9f3ff;
  border-radius: 8px;
  padding: 5px;
  margin-bottom: 5px !important; */
  cursor: pointer;
  display: flex;
  align-items: center;
  flex: 0 0 100%;
  padding: 0.65rem 1rem;
  /* transition: none; */
  border-radius: 8px;
  outline: 0 !important;
  background: #e9f3ff;
  margin: 10px 0px !important;
}

.rbd-announcement-0 {
  display: none !important;
}
.modal-footer-action-container {
  margin-left: -50px !important;
}

@media screen and (max-width: 576px) {
  .css-19kzrtu {
    padding: 10px !important;
  }
}
.hide-save-button {
  display: none;
}

@media screen and (max-width: 950px) {
  .button-settings-container {
    display: none;
  }
  .hide-save-button {
    display: block;
  }
}

/* .main-checkout-container {
  display: flex;
  flex-direction: column;
  width: 50%;
} */
/* .button-settings-container {
  text-align: end;
  width: 100%;
  margin-top: -10px;
  margin-bottom: 15px;
}
.save-btn {
  width: fit-content !important;
} */

.check-parent-div {
  display: flex;
  justify-content: space-between;
  /* padding: 15px; */
  padding: 16px 24px;
}
.checkout-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 16px 24px;
}
.main-container {
  width: 45%;
  /* padding-right: 15px; */
}
.checkout-hr-line {
  background: grey;
  height: 1px;
}
.hr-line-checkout-label-container {
  display: flex;
  flex-direction: column;
}
