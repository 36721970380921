.breadcrumb-item {
  display: flex;
  justify-content: flex-start;
  gap: 6px;
  font-size: 14px;
  color: var(--primary-darker, #0b0b2d);
  font-weight: 500;
  white-space: nowrap;
  text-align: center;
  align-items: center;
  cursor: pointer;
}
.breadcrumb-img-text-container {
  display: flex;
  text-wrap: wrap;
}

.containers {
  display: flex;
  justify-content: space-between; /* Space out the content */
  align-items: center; /* Vertically center the items */
  width: 100%;
  flex-wrap: wrap;
}
.breadcrumb-img {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 20px;
  cursor: pointer;
}

.breadcrumb-title {
  font-feature-settings: "ss11" on, "cv09" on, "liga" off, "calt" off;
  font-family: Inter, sans-serif;
  cursor: pointer;
  /* text-wrap: wrap; */
  /* word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal; */
}

.language-select-wrapper label {
  margin-right: 20px;
  white-space: nowrap;
  font-size: 13px;
  font-family: Inter !important;
}
.language-select-wrapper select {
  padding: 0 15px 0 10px !important;
  height: 30px !important;
  font-size: 13px !important;
}
.toggle-menu-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}
.toggle-grey {
  opacity: 0.5;
  filter: grayscale(100%);
}
.test-toggle-color {
  color: #cc4b00;
}
/* Styles for the unchecked state */
input.toggle-switch:not(:checked) {
  box-shadow: inset -20px 0 0 0 #cc4b00 !important; /* Change box shadow color */
  border-color: #cc4b00 !important; /* Change border color */
}
input.toggle-switch:checked {
  box-shadow: inset 20px 0 0 0 #cc4b00 !important;
  border-color: #cc4b00 !important;
}
input.toggle-switch:not(:checked):after {
  left: 1px; /* Reset to initial position */
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.2); /* Reset box shadow */
}
input.toggle-switch:checked:after {
  left: 20px;
  box-shadow: -2px 4px 3px rgba(0, 0, 0, 0.05);
}
input.toggle-switch:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  background: transparent;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.2);
}
input.toggle-switch {
  position: relative;
  -webkit-appearance: none;
  outline: none;
  width: 44px !important;
  height: 26px !important;
  background-color: #fff !important;
  border: 1px solid #d9dadc !important;
  border-radius: 50px !important;
  box-shadow: inset -20px 0 0 0 #fff !important;
  cursor: pointer;
}
