@import "../src/components/keenicons/duotone/demo-files/demo.css";
@import "../src/components/keenicons/duotone/style.css";
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

:root {
  --Gray-100: #f9f9f9;
  --Gray-200: #f1f1f4;
  --Gray-300: #dbdfe9;
  --Gray-400: #c4cada;
  --Gray-500: #99a1b7;
  --Gray-600: #78829d;
  --Gray-700: #4b5675;
  --Gray-800: #252f4a;
  --Gray-900: #071437;
  --Bg-Danger: #ffeef3;
  --Text-Danger: #f8285a;
  --Bg-success: #dfffea;
  --Text-success: #17c653;
  --Bg-primary: #1b84ff;
}

body {
  font-family: "Inter", serif !important;
  font-size: 13px !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.container-login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading-indicator {
  max-width: 100%;
  max-height: 100%;
}

/* stryles from Metronic theme */
body {
  background-color: #f9f9f9 !important;
}
#root {
  display: contents;
}
.flex-root {
  flex: 1;
}
.flex-column-fluid {
  flex: 1 0 auto;
}

@media (min-width: 576px) {
  .aside-fixed .aside {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 101;
    overflow-y: auto;
  }
}

@media (min-width: 992px) {
  .aside {
    width: 140px;
  }
}

.aside {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 28px 0 rgba(82, 63, 105, 0.05);
  background-color: #090a0d;
  padding: 0;
}
/* @media (min-width: 992px) { */
.header-fixed.toolbar-fixed .wrapper {
  /* padding-top: 140px; */
  /* padding-top: 60px; */
}
/* } */

/* @media (min-width: 992px) { */
.aside-enabled.aside-fixed .wrapper {
  padding-left: 140px;
}
/* } */

/* @media (min-width: 992px) { */
/* .header-fixed .wrapper {
  padding-top: 65px;
} */
/* } */

.flex-row-fluid {
  flex: 1 auto;
  min-width: 0;
}
/* @media (min-width: 992px) { */
.aside .aside-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--bs-aside-logo-bg-color);
  height: 120px;
  padding: 0 25px;
  flex-shrink: 0;
}
/* } */

.py-8 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}
@media (min-width: 992px) {
  .aside-enabled.aside-fixed.header-fixed .header {
    left: 140px;
  }
}

@media (min-width: 992px) {
  .header-fixed.toolbar-fixed .header {
    box-shadow: none;
  }
}

@media (min-width: 992px) {
  .header-fixed .header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 100;
    background-color: #fff;
    height: 65px;
    box-shadow: var(--bs-header-header-fixed-box-shadow);
    padding: 0;
  }
}

@media (min-width: 992px) {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 65px;
    background-color: #fff;
    border-bottom: 1px solid #f1f1f4;
  }
}
/* @media (min-width: 992px) { */
main,
span,
ol,
ul,
pre,
div {
  scrollbar-width: thin;
  scrollbar-color: #585876 transparent;
}
/* } */
.align-items-stretch {
  align-items: stretch !important;
}
@media (min-width: 992px) {
  .container,
  .container-xxl,
  .container-fluid,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    padding: 0 30px;
  }
}
.ms-n1 {
  margin-left: -0.25rem !important;
}
@media (min-width: 992px) {
  .content {
    padding: 30px 0;
  }
}
@media (min-width: 992px) {
  .toolbar-enabled.toolbar-fixed .content {
    padding-top: 0;
  }
}
@media (min-width: 992px) {
  .aside-enabled.aside-fixed.toolbar-fixed .toolbar {
    left: 145px;
    transition: left ease;
  }
}

@media (min-width: 992px) {
  .header-fixed.toolbar-fixed .toolbar {
    padding: 5px 0 0 0;
    /* top: 65px; */
    top: 0px;
  }
}

@media (min-width: 992px) {
  .toolbar-fixed .toolbar {
    height: var(--bs-toolbar-height);
    /* position: fixed; */
    top: 0;
    right: 0;
    left: 0;
    z-index: 99;
    box-shadow: none;
  }
}

@media (min-width: 992px) {
  .toolbar {
    border-bottom: 1px solid #f1f1f4;
  }
}

.toolbar {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 0.5rem;
}

@media (min-width: 992px) {
  .container,
  .container-xxl,
  .container-fluid,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    padding: 0 30px;
  }
}
.card {
  box-shadow: 0px 0px 20px 0px rgba(76, 87, 125, 0.02);
  border-color: #f1f1f4;
  border: 0 !important;
}
.pt-6 {
  padding-top: 1.5rem !important;
}
.card .card-header {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  min-height: 70px;
  padding: 0 2.25rem;
  color: var(--bs-card-cap-color);
  background-color: transparent;
  border-bottom: 1px solid #f1f1f4;
}
.card .card-header .card-title,
.card .card-header .card-title .card-label {
  font-weight: 500;
  font-size: 1.275rem;
  color: #071437;
}
.card .card-header .card-toolbar {
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
  flex-wrap: wrap;
}
.btn:not(.btn-outline):not(.btn-dashed):not(.btn-bordered):not(
    .border-hover
  ):not(.border-active):not(.btn-flush):not(.btn-icon):not(.btn-hover-outline) {
  border: 0;
  padding: calc(0.475rem + 1px) calc(1.1rem + 1px);
}

.btn:not(.btn-shadow):not(.shadow):not(.shadow-sm):not(.shadow-lg):not(
    .shadow-xs
  ) {
  box-shadow: none;
}

/* .btn{
  height: 43px !important;
} */

.btn.btn-primary {
  color: #ffffff !important;
  border-color: #1b84ff !important;
  background-color: #1b84ff !important;
  text-transform: capitalize !important;
}
.btn-check:active + .btn.btn-primary,
.btn-check:checked + .btn.btn-primary,
.btn.btn-primary.active,
.btn.btn-primary.show,
.btn.btn-primary:active:not(.btn-active),
.btn.btn-primary:focus:not(.btn-active),
.btn.btn-primary:hover:not(.btn-active),
.show > .btn.btn-primary {
  color: #ffffff !important;
  border-color: #056ee9 !important;
  background-color: #056ee9 !important;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

.btn {
  color: #071437;
  outline: none !important;
}
.btn.btn-primary i,
.btn.btn-primary .svg-icon {
  color: #fff;
}

.btn > i {
  display: inline-flex;
  font-size: 1rem;
  padding-right: 0.35rem;
  vertical-align: middle;
}
.card .card-body {
  padding: 2rem 2.25rem;
  color: var(--bs-card-color);
}

.py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.aside-menu .hover-scroll-y,
.aside-menu .hover-scroll-overlay-y {
  --bs-scrollbar-space: 0.4rem;
  scrollbar-color: #3b3b64 transparent;
}

.hover-scroll,
.hover-scroll-overlay,
.hover-scroll-x,
.hover-scroll-overlay-x,
.hover-scroll-y,
.hover-scroll-overlay-y {
  scrollbar-color: transparent transparent;
}

.scroll-y,
.hover-scroll-y,
.hover-scroll-overlay-y {
  overflow-y: scroll;
  position: relative;
}

@media (min-width: 992px) {
  .my-lg-5 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
table.dataTable {
  width: 100% !important;
  margin: 0 !important;
}
.fs-6 {
  font-size: 1.075rem !important;
}
.table:not(.table-bordered).table-row-dashed tr {
  /* border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #CACFd8; */
}

.table:not(.table-bordered) tr:last-child,
.table:not(.table-bordered) th:last-child,
.table:not(.table-bordered) td:last-child {
  padding-right: 0;
}

.table:not(.table-bordered) tr:first-child,
.table:not(.table-bordered) th:first-child,
.table:not(.table-bordered) td:first-child {
  padding-left: 0;
}
.table-responsive .css-1enotpu-MuiTableRow-root:nth-of-type(odd) {
  background: transparent;
}

.table:not(.table-bordered) tr,
.table:not(.table-bordered) th,
.table:not(.table-bordered) td {
  border-color: inherit;
  border-width: inherit;
  border-style: inherit;
  text-transform: normal !important;
  font-weight: inherit;
  font-size: inherit;
  color: inherit;
  height: inherit;
  min-height: inherit;
}
.table:not(.table-bordered) > :not(:last-child) > :last-child > * {
  border-bottom-color: #f1f1f4;
}

table.dataTable > thead > tr > td:not(.sorting_disabled),
table.dataTable > thead > tr > th:not(.sorting_disabled) {
  padding-right: 0;
}

.table.gy-5 th,
.table.gy-5 td {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  background-color: transparent;
  border: 0 none;
  color: #78829d !important;
  font-size: 13px !important;
  font-weight: 600 !important;
}
.text-muted {
  color: #99a1b7 !important;
}
.table-header-text,
.chip-title,
.settings-title,
.status-title {
  font-family: Inter !important;
}
.table-header-text {
  color: #525866;
  /* color: var(--Gray-500) !important; */
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  line-height: 20px;
  text-transform: capitalize !important;
}
.table-data-text {
  color: var(--Gray-600) !important;
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  line-height: 20px;
}
.settings-title,
a.settings-title {
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 20px;
  color: var(--Gray-800) !important;
}
a.settings-title:hover {
  color: #1b84ff !important;
  text-decoration: none;
  box-shadow: none;
}

.chip-container {
  justify-content: center;
  border-radius: var(--radius-full, 999px);
  background-color: var(--state-faded-lighter, #f2f5f8);
  display: flex;
  gap: 0px;
  font-size: 12px;
  color: var(--state-faded-base, #717784);
  font-weight: 500;
  display: inline-flex;
  padding: 2px 8px 2px 8px;
  margin: 2px;
}
.chip-status {
  width: 6px;
  height: 6px;
  margin: auto 0;
  background-color: #717784;
  border-radius: 50%;
}
.chip-title {
  padding-left: 6px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: var(--Gray-800) !important;
}

.status-container.inactive-status .status-title,
.status-container.active-status .status-title {
  display: inline-block;
  padding: 0.325rem 0.5rem;
  font-size: 12px;
  font-weight: 600;
  color: #071437;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 6px;
}
.status-container.inactive-status .status-title {
  background-color: var(--Bg-Danger);
  color: var(--Text-Danger) !important;
}
.status-container.active-status .status-title {
  background-color: var(--Bg-success);
  color: var(--Text-success) !important;
}

table button.base-MenuButton-root:hover {
  background: transparent;
}
.table-sort-icon {
  margin-top: -8px;
}
.action-menu-item {
  display: flex;
  align-items: center;
}

.action-menu-icon {
  padding-right: 6px;
}

.base-Popup-root ul.base-Menu-listbox {
  background: #ffffff !important;
  padding: 10px !important;
  border-radius: 2px !important;
  box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15) !important;
  border: none !important;
  max-height: 500px !important;
  overflow: auto !important;
}

.base-Popup-root ul.base-Menu-listbox li {
  cursor: pointer;
  display: flex;
  align-items: center;
  flex: 0 0 100%;
  padding: 0.65rem 1rem;
  transition: none;
  outline: 0 !important;
}

.base-Popup-root ul.base-Menu-listbox li:hover {
  transition: color 0.2s ease;
  background-color: #e9f3ff;
  color: #1b84ff;
}

.base-Popup-root ul.base-Menu-listbox li:focus {
  outline: none;
}

.base-Popup-root ul.base-Menu-listbox li:hover .action-menu-icon svg path {
  fill: #1b84ff !important;
}

.filter-toggle-container .Mui-selected {
  border-radius: 4px !important;
  color: #ffffff !important;
  background-color: #1b84ff !important;
  line-height: normal;
}
.filter-toggle-container button:hover {
  background: var(--Bg-primary);
  border-radius: 4px;
  color: #fff;
}

/* .status-title {
  color: #525866;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  padding-left: 4px;
} */

/* .status-img {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 16px;
  margin: auto 0;
} */

.table > :not(caption) > * > * {
  padding: 0.75rem 0.75rem;
  color: var(initial, initial, #071437);
  background-color: transparent;
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px transparent, transparent, transparent;
}
.card-body .table-responsive {
  box-shadow: none !important;
}
.btn-check:checked + .btn.btn-active-light-primary,
.btn-check:active + .btn.btn-active-light-primary,
.btn.btn-active-light-primary:focus:not(.btn-active),
.btn.btn-active-light-primary:hover:not(.btn-active),
.btn.btn-active-light-primary:active:not(.btn-active),
.btn.btn-active-light-primary.active,
.btn.btn-active-light-primary.show,
.show > .btn.btn-active-light-primary {
  color: #1b84ff;
  border-color: #e9f3ff;
  background-color: #e9f3ff !important;
}

.btn-check:checked + .btn.btn-light,
.btn-check:active + .btn.btn-light,
.btn.btn-light:focus:not(.btn-active),
.btn.btn-light:hover:not(.btn-active),
.btn.btn-light:active:not(.btn-active),
.btn.btn-light.active,
.btn.btn-light.show,
.show > .btn.btn-light {
  color: var(--bs-light-inverse);
  border-color: var(--bs-light-active);
  background-color: var(--bs-light-active) !important;
}
.header-title {
  color: var(--Gray-900) !important;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.4;
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
}
@media (max-width: 991px) {
  .header-title {
    max-width: 100%;
  }
}
.header-sub-title {
  color: var(--Gray-600) !important;
  margin-top: 12px;
  font-weight: 400;
  font-size: 1.08rem;
  line-height: 1.3;
  font-family: Inter, -apple-system, Roboto, Helvetica, sans-serif;
}
@media (max-width: 991px) {
  .header-sub-title {
    max-width: 100%;
  }
}
.rulescontainer {
  padding-top: 15px;
}
/* Styles for the unchecked state */
input.apple-switch:not(:checked) {
  box-shadow: inset -20px 0 0 0 #1b84ff !important; /* Change box shadow color */
  border-color: #1b84ff !important; /* Change border color */
}
input.apple-switch:checked {
  box-shadow: inset 20px 0 0 0 #1b84ff !important;
  border-color: #1b84ff !important;
}
input.apple-switch:not(:checked):after {
  left: 1px; /* Reset to initial position */
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.2); /* Reset box shadow */
}
input.apple-switch:checked:after {
  left: 20px;
  box-shadow: -2px 4px 3px rgba(0, 0, 0, 0.05);
}
input.apple-switch:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  background: transparent;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.2);
}
input.apple-switch {
  position: relative;
  -webkit-appearance: none;
  outline: none;
  width: 44px !important;
  height: 26px !important;
  background-color: #fff !important;
  border: 1px solid #d9dadc !important;
  border-radius: 50px !important;
  box-shadow: inset -20px 0 0 0 #fff !important;
  cursor: pointer;
}
.btn-group-sm
  > .btn:not(.btn-outline):not(.btn-dashed):not(.btn-bordered):not(
    .border-hover
  ):not(.border-active):not(.btn-flush):not(.btn-icon):not(.btn-hover-outline),
.btn:not(.btn-outline):not(.btn-dashed):not(.btn-bordered):not(
    .border-hover
  ):not(.border-active):not(.btn-flush):not(.btn-icon):not(
    .btn-hover-outline
  ).btn-sm {
  padding: calc(0.55rem + 1px) calc(1rem + 1px);
}
.btn:not(.btn-outline):not(.btn-dashed):not(.btn-bordered):not(
    .border-hover
  ):not(.border-active):not(.btn-flush):not(.btn-icon):not(.btn-hover-outline) {
  border: 0;
  /* padding: calc(.775rem + 1px) calc(1.5rem + 1px); */
}
.btn:not(.btn-shadow):not(.shadow):not(.shadow-sm):not(.shadow-lg):not(
    .shadow-xs
  ) {
  box-shadow: none;
}
.btn.btn-light-primary {
  color: #1b84ff !important;
  border-color: #e9f3ff !important;
  background-color: #e9f3ff !important;
}
.btn-check:active + .btn.btn-light-primary,
.btn-check:checked + .btn.btn-light-primary,
.btn.btn-light-primary.active,
.btn.btn-light-primary.show,
.btn.btn-light-primary:active:not(.btn-active),
.btn.btn-light-primary:focus:not(.btn-active),
.btn.btn-light-primary:hover:not(.btn-active),
.show > .btn.btn-light-primary {
  color: #fff !important;
  border-color: #1b84ff !important;
  background-color: #1b84ff !important;
}
.btn.btn-flex {
  display: inline-flex;
  align-items: center;
}
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}
.text-gray-800 {
  color: #252f4a !important;
}
.fw-bold {
  font-weight: 600 !important;
}
.m-0 {
  margin: 0 !important;
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  outline: 0;
}
@media (min-width: 1200px) {
  h3,
  .h3 {
    font-size: 1.25rem !important;
  }
}
@media (max-width: 1199px) {
  h3,
  .h3 {
    font-size: 1.15rem !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 1.75rem !important;
  }
}
@media (max-width: 1199px) {
  .fs-1 {
    font-size: 1.25rem !important;
  }
  .arrow-icon.double {
    height: 1.1rem;
    width: 1.1rem;
  }
}
.form-control {
  display: block;
  width: 100%;
  padding: 19px 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: #4b5675;
  appearance: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dbdfe9;
  border-radius: 0.475rem;
  box-shadow: false;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.css-1p3m7a8-multiValue {
  background-color: #f1f1f4;
  border-radius: 5px;
}
.btn-check:checked + .btn.btn-primary,
.btn-check:active + .btn.btn-primary,
.btn.btn-primary:focus:not(.btn-active),
.btn.btn-primary:hover:not(.btn-active),
.btn.btn-primary:active:not(.btn-active),
.btn.btn-primary.active,
.btn.btn-primary.show,
.show > .btn.btn-primary {
  color: #fff;
  border-color: #056fe9;
  background-color: #056fe9 !important;
}
.rule-box {
  box-shadow: 0px 0px 20px 0px rgb(76 87 125 / 38%);
}

/*input css*/
input,
textarea,
select {
  display: block !important;
  width: 100% !important;
  font-size: 14px !important;
  padding: 0.775rem 1rem;
  line-height: 1.5 !important;
  color: #4b5675 !important;
  background-color: #fff !important;
  border: 1px solid #ced4da;
  border-radius: 6px !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  height: 100% !important;
  font-family: Inter !important;
  box-shadow: none !important;
}

input:focus,
textarea:focus,
select:focus {
  outline: 0 !important;
  background-color: #f9f9f9 !important;
}

input:disabled,
textarea:disabled {
  background-color: #e9ecef !important;
  opacity: 1 !important;
}

/*Modal CSS*/

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.modal-body {
  padding: 24px 32px;
  /* max-height: 60vh; */
  max-height: 40vh;
  overflow: auto;
}
.modal-content {
  background-color: #fff;
  /* padding: 25px; */
  border-radius: 26px !important;
  text-align: center;
  width: 550px;
}
.modal-content-processor {
  text-align: center;
}
@media screen and (max-width: 768px) {
  .modal-content {
    width: 80% !important;
  }
}
.modal-content p {
  text-align: left;
  padding: 0px 10px;
}

.close-button {
  cursor: pointer;
  float: right;
  font-size: 20px;
}

.modal-header {
  /* align-self: stretch; */
  border: 0;
  border-bottom-color: rgba(202, 207, 216, 1);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  background-color: var(--bg-white-0, #fff);
  display: flex;
  padding: 24px 32px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  /* display: flex; */
  justify-content: space-between;
  /* align-items: center; */
}
/* @media (max-width: 991px) {
  .modal-header {
    flex-wrap: wrap;
  }
} */

.modal-header-container {
  display: flex;
}
.modal-header-container svg path {
  color: var(--Bg-primary);
}
.modal-header-img-container {
  justify-content: center;
  align-items: center;
  border-radius: var(--radius-full, 999px);
  border-color: rgba(202, 207, 216, 1);
  border-style: solid;
  border-width: 1px;
  background-color: var(--bg-white-0, #fff);
  display: flex;
  width: 40px;
  height: 40px;
}
.modal-header-img {
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 20px;
}
.modal-header-title-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 14px;
  text-align: left;
}
@media (max-width: 991px) {
  .modal-header-title-container {
    max-width: 100%;
  }
}
.accordian-summary {
  height: 32px;
  padding: 8px 12px 8px 12px;
  gap: 6px;
  border-radius: 6px 0px 0px 0px;
  opacity: 0px;
  background-color: var(--bg-weak-50, #f5f7fa);
}

.modal-accordion,
.modal-accordion .MuiAccordionSummary-root {
  width: 100% !important;
}
.modal-header-title {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  font-family: "Inter", "Helvetica", "Arial", sans-serif;
  color: var(--Bg-primary);
}
@media (max-width: 991px) {
  .modal-header-title {
    max-width: 100%;
  }
}
.modal-header-subtitle {
  color: var(--text-sub-600, #525866);
  margin-top: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
@media (max-width: 991px) {
  .modal-header-subtitle {
    max-width: 100%;
  }
}

.modal-header-close {
  cursor: pointer;
}

.modal-footer {
  align-self: stretch;
  background-color: var(--bg-white-0, #fff);
  display: flex;
  /* max-width: 648px; */
  flex-direction: column;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.08px;
  line-height: 143%;
  justify-content: center;
  /* padding: 24px 32px 0 !important; */

  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}
@media (max-width: 991px) {
  .modal-footer-container {
    max-width: 100%;
    flex-wrap: wrap;
  }
}

/* Tabs css */
.line-tabs .MuiTabs-root {
  border-bottom: 1px solid var(--Gray-200) !important;
}

.line-tabs .MuiTab-root {
  text-transform: none;
  font-size: 15px;
  color: var(--Gray-700) !important;
  padding: 0.6rem 0;
  margin-right: 30px;
  font-weight: 600;
  font-family: Inter !important;
  transition: color 0.1s ease, border-bottom 0.1s ease;
  border-bottom: 2px solid transparent;
}

.line-tabs .MuiTab-root:hover {
  color: var(--Bg-primary) !important;
  border-bottom: 2px solid var(--Bg-primary);
  background-color: transparent;
}

.line-tabs .MuiTab-root.Mui-selected {
  color: var(--Bg-primary) !important;
  font-weight: 600;
  border-bottom: 2px solid var(--Bg-primary);
}

.line-tabs .MuiTabs-indicator {
  background-color: var(--Bg-primary);
  height: 2px;
}
.card-body h6 {
  font-family: Inter !important;
  font-size: 16px !important;
  font-weight: 500;
  line-height: 24px !important;
  color: var(--Gray-600) !important;
}
.card-body .creds-title {
  font-family: Inter !important;
  font-size: 18px !important;
  font-weight: 600;
  line-height: 24px !important;
  color: var(--Gray-900) !important;
  margin: 10px 0 15px !important;
}
.MuiRadio-colorPrimary .MuiSvgIcon-fontSizeMedium {
  fill: #1b84ff !important;
}
.chips-main .MuiChip-root {
  color: #1b84ff !important;
  border-color: #e9f3ff !important;
  background-color: #e9f3ff !important;
}
.chips-main .MuiChip-label {
  font-size: 12px !important;
  line-height: normal !important;
  font-weight: 500 !important;
}
.chips-main .MuiChip-root .settings-title {
  color: #1b84ff !important;
  margin: 0;
}
.MuiFormControlLabel-root .MuiSwitch-track {
  background-color: #c2c2c2 !important;
}
.MuiFormControlLabel-root .Mui-checked + .MuiSwitch-track {
  background-color: #1b84ff !important;
}
/*Pagination styling*/
nav.MuiPagination-root .Mui-selected {
  color: #ffffff !important;
  background-color: var(--Bg-primary) !important;
  border-color: var(--Bg-primary) !important;
}
nav.MuiPagination-root .MuiPaginationItem-root:hover {
  color: #ffffff !important;
  background-color: var(--Bg-primary) !important;
  border-color: var(--Bg-primary) !important;
}
.popup-wrapper-section-content {
  width: 83px !important;
}
.spinner {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 9px solid;
  border-color: #dbdcef;
  border-right-color: #1b84ff;
  animation: spinner-d3wgkg 1s infinite linear;
}

@keyframes spinner-d3wgkg {
  to {
    transform: rotate(1turn);
  }
}

.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 576px) {
  .aside {
    display: flex !important;
    overflow: auto;
    z-index: 110;
    position: fixed;
    top: 0;
    bottom: 0;
    transition: transform 0.3s ease-in-out !important;
    left: 0;
    transform: none;
    box-shadow: 0px 1px 9px -3px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease-in-out !important;
    padding-top: 0px !important;
  }
  .aside-enabled.aside-fixed .wrapper {
    padding-left: 0px;
  }
  .contracted .aside {
    display: none !important;
  }
  .contracted {
    width: 0px !important;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
