@media screen and (min-width: 640px) {
  .space-between {
    display: flex;
    justify-content: flex-end !important;
    gap: 20px;
  }
}
.spinner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.payment-processor-alignment-container {
  /* display: grid;
  grid-template-columns: repeat(6, 1fr); */
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  margin-top: 20px;
  justify-content: center;
  /* padding: 24px 50px; */
  /* background: plum; */
}

.payment-processor-search {
  /* padding: 0px 8px; */
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 40px !important;
  width: 250px !important;
}

.processor-currency-dropdown {
  /* width: 30% !important; */
  width: 150px;
  border-radius: 8px;
}
@media screen and (max-width: 576px) {
  .processor-currency-dropdown {
    min-width: 250px !important;
  }
}

.cancel-add-processor-container {
  display: flex;
  justify-content: flex-end;
  padding: 24px 60px;
}
.each-dropdown-menu-item {
  height: 33px !important;
  margin: 10px !important;
}
.each-dropdown-menu-item:hover {
  background: rgba(0, 123, 255, 0.1) !important;
  color: #1b84ff !important;
}

.hr-line {
  background: grey;
  height: 1px;
  margin: 0px 15px;
}
.processors-dropdown {
  width: 100% !important;
}

@media screen and (max-width: 576px) {
  .responsive-card {
    padding: 0px !important;
  }
}

.apply-cancel-button-container {
  display: flex;
  gap: 15px !important;
}
.dropdown-search-field {
  margin: 10px 20px;
}

.no-data-found-container {
  display: flex;
  justify-content: center;
  align-items: cnter;
}
.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  max-height: 600px !important;
}
.css-6hp17o-MuiList-root-MuiMenu-list {
  max-height: 600px !important;
}
