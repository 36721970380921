.test-mode-banner {
  width: 100%;
  background-color: #cc4b00; /* Orange color */
  color: white;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 600;
  top: 0;
  z-index: 1000;
}

.test-mode-label {
  margin-left: 16px;
}

.test-mode-message {
  position: absolute;
  left: 55%;
  transform: translateX(-50%);
  text-align: center;
  font-size: 14px;
  font-weight: 600;
}
