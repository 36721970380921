.form-control {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  height: 43px;
}
.calendar-input {
  width: 100%;
  padding: 8px;
  border: 0 none;
  border-radius: 4px;
  padding: calc(0.55rem + 1px) calc(1rem + 1px);
  font-family: helvatica, sans-serif;
  color: #99a1b7;
  background-color: #f9f9f9;
  cursor: pointer;
  font-size: 0.95rem;
  font-weight: 600;
}
button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

button + button {
  background-color: #ccc;
}

button + button:hover {
  background-color: #999;
}

.calendar-popup {
  position: absolute;
  z-index: 10;
  background: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  width: auto;
  border-radius: 4px;
}

.calendar-header {
  align-items: center;
  margin-bottom: 10px;
  color: #252f4a;
  font-weight: bold;
  font-size: 13px;
}

.calendar-header button {
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.calendar-header button:hover {
  color: #007bff;
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 0px;
  text-align: center;
}

.calendar-day,
.week-day {
  padding: 9px;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 4px;
  font-size: 12px;
}
.calendar-day {
  color: #4b5675;
}
.week-day {
  font-weight: bold;
}
.calendar-day.selected {
  background-color: #1b84ff;
  color: white;
}
.start {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.end {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.calendar-day:hover {
  background-color: #f9f9f9;
  color: #1b84ff;
}

.calendar-day.disabled {
  color: #ccc;
  cursor: not-allowed;
}

.calendar-day.range-selected {
  background-color: #f9f9f9;
  color: #1b84ff;
}

.calendar-day.range-hover {
  background-color: #f9f9f9;
  color: #1b84ff;
}

.calendar-actions {
  text-align: right;
  margin-top: 10px;
}

.calendar-actions button {
  margin-left: 10px;
  padding: 5px 10px;
}

.daterangepicker {
  display: flex;
  flex-direction: row; /* Align children horizontally */
  padding: 0;
  margin: 0;
  border: 0;
  width: auto;
  background-color: #fff;
  box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
  font-family: Inter, Helvetica, sans-serif;
  font-size: 15px;
  z-index: 1000;
  border-radius: 0.475rem;
  line-height: 1em;
  position: absolute;
  color: #071437;
}

.ranges {
  background-color: #fff;
  width: 150px;
  /* overflow: auto; */
  margin-right: 20px; /* Add spacing between ranges and calendars */
}

.daterangepicker .ranges ul {
  padding: 1rem 0;
  list-style: none;
  margin: 0 auto;
}

.daterangepicker .ranges li {
  cursor: pointer;
  padding: 0.7rem 1.75rem;
  font-weight: 500;
  font-size: 12px;
  color: #78829d;
  background: transparent;
  transition: color 0.2s ease;
}

.daterangepicker .ranges li.active {
  background-color: #1b84ff;
  color: white;
}

@media screen and (max-width: 576px) {
  .daterangepicker {
    display: flex;
    flex-direction: column;
    width: 290px;
    /* padding: 5px; */
  }
  .ranges {
    width: 290px !important;
    display: flex;
    flex-wrap: wrap !important;
    /* background: plum !important;  */
  }
  .calendar-section {
    display: flex;
    overflow-x: scroll !important;
  }
  .daterangepicker .ranges ul {
    display: flex;
    flex-wrap: wrap;
  }
  .daterangepicker .ranges ul li {
    width: 140px !important;
  }
  .daterangepicker-buttons-container {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
}

.daterangepicker .ranges li:hover {
  background-color: #f9f9f9;
  color: #1b84ff;
}

.daterangepicker .ranges li:active {
  background-color: #1b84ff;
  color: white;
}

.daterangepicker .ranges li span {
  padding: 0 10px;
  font-size: 12px;
  color: #071437;
  background-color: transparent;
}

.calendar-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 0px; /* Spacing between the calendars */
  /* background-color: plum; */
  /* flex-wrap: wrap;
  overflow-x: scroll;
  width: 200px; */
}

.calendar {
  flex: 1;
  padding: 1rem 1rem;
}

.calendar-actions {
  text-align: center;
  margin-top: 20px;
}

.calendar-actions button {
  margin: 0 10px;
  padding: 5px 10px;
}
.drp-buttons {
  line-height: 12px;
  vertical-align: middle;
  text-align: right;
  padding: 1rem 1.75rem;
  border-top: 1px solid #f1f1f4;
}
.drp-selected {
  font-size: 13px;
  padding-right: 20px;
}
.btn-sm {
  font-size: 13px;
  font-weight: 500;
  padding: calc(0.55rem + 1px) calc(1rem + 1px);
}
.cancelBtn {
  color: #252f4a;
  background-color: #f9f9f9;
  margin-left: 8px;
}
.applyBtn {
  background-color: #1b84ff;
}
.week-names {
  display: flex;
  justify-content: space-between;
}
.week-name {
  flex: 1;
  text-align: center;
}
.week-row {
  display: flex;
  justify-content: space-between;
}
.prev {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  /* border-radius: 0; */
  display: inline-block;
  padding: 3px;
  /* background-color: transparent; */
  color: #fff;
  border: solid #000;
  border-width: 0 1px 1px 0;
  border-color: #99a1b7;
}
.prev-btn,
.next-btn {
  background-color: transparent;
}
.prev-btn:hover,
.next-btn:hover {
  border-radius: 0.475rem;
  background-color: #f9f9f9;
  color: #1b84ff;
  border-color: transparent;
}

.next {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  /* border-radius: 0; */
  display: inline-block;
  padding: 3px;
  /* background-color: transparent; */
  color: #fff;
  border: solid #000;
  border-width: 0 1px 1px 0;
  border-color: #99a1b7;
}
.padding {
  padding: 0.775rem 1rem !important;
  border: 1px solid #ced4da !important;
}
