.filter-button {
  display: flex;
  padding: 3px 15px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  border-radius: 0.375rem;
  background: #e9f3ff;
  /* margin-left: 10px; */
  cursor: pointer;
  color: #1b84ff;
  margin-right: 10px;
}
.filter-button p {
  color: #1b84ff;
}
.filter-button svg path {
  fill: #1b84ff;
}
.filter-button:hover {
  background: #1b84ff;
}
.filter-button:hover p {
  color: #fff;
}
.filter-button:hover i.ki-duotone.ki-filter {
  color: #fff;
}
i.ki-duotone.ki-filter {
  color: #1b84ff;
}
.caret-down-icon {
  color: white !important;
}

.filter-dropdown-container {
  display: flex;
  padding: 0px 10px;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  align-self: stretch;
  border-radius: var(--radius-10, 10px);
  border: 1px solid var(--stroke-soft-200, #cacfd8);
  box-shadow: 0px 1px 2px 0px rgba(10, 13, 20, 0.03);
}

.chart-container {
  max-width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.card-header {
  margin-top: 10px;
}
.responsive-grid {
  max-width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}
.card {
  padding: 10px;
}
@media (max-width: 768px) {
  .chart-container {
    grid-template-columns: 1fr;
  }
}
